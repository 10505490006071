<template>
    <v-row justify="center">
        <v-col v-if="modelo.estado!='PAGADO'" :lg="vertical?'12':'3'" :class="center?'text-center':''">
            <img :src="modelo.codigoQr" style="width:250px!important"/>
        </v-col>
        <v-col :lg="vertical?'12':lg" :class="center?'text-center':''">
            <h5 class="grey--text pb-2">Código Qr generado para su {{titulo}}</h5>
            <h4>ID QR: {{modelo.idQr}} <v-icon v-if="modelo.codigoQr" @click="downloadImage" class="ml-5">mdi-download</v-icon></h4> 
            <p>Monto a pagar: {{modelo.monto}} Bs.</p>
            <p>Estado Código: 
                <v-chip
                text-color="white"
                small
                :color="getColorEstado(modelo.estado)"
                >
                    {{modelo.estado}}
                </v-chip>
            </p>
        </v-col>
    </v-row>     
</template>
<script>
import mixinDateTime from "@/mixins/datetime" 
export default {
    props:{
        qr:{
            type: Object,
            default: ()=>{
                return {
                    id_qr:'XXXX-XXXX-XXXXX',
                    codigo_qr:null,
                    fecha_creacion: '2021-02-01',
                    fechaVencimiento:'',
                    estado:'CREADO',
                    monto:0
                }
            }
        },
        titulo:{
            type: String,
            default: 'Trámite'
        },
        center:{
            type: Boolean,
            default: false
        },
        vertical:{
            type:Boolean,
            default:false
        }
    },
    mixins: [mixinDateTime],
    data(){
        return{
            loading:false,
            modelo :this.qr,
            lg:this.qr.estado==='PAGADO'?'12':'3'
        }
    },
    methods:{
        getColorEstado(estado){
            return estado == 'CREADO'?'grey':estado == 'PAGADO'?'secondary lighten-2':'success'
        },
        downloadImage() {
            var blob = this.base64toBlob(this.qr.codigoQr.split(',')[1], 'image/png');
            var a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = 'qr_pago_rpa.png';
            a.click();
        },
        base64toBlob(base64Data, contentType) {
            contentType = contentType || '';
            var sliceSize = 1024;
            var byteCharacters = atob(base64Data);
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            return new Blob(byteArrays, { type: contentType });
        }
    }
}
</script>